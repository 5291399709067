import React, { Component } from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import SEO from '../components/seo'

class IndexPage extends Component {
  render() {
    const { data } = this.props;
    return (
      <Layout>
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />

        <section>
          <div className="container">
              <div dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }} />
          </div>
        </section>

        {/* <div className="dots__wrapper">
          <div className="dots"></div>
        </div> */}

        <div className="photo"></div>
        
      </Layout>
    )
  }
}

export const query = graphql`
  query HomePageQuery {
    wordpressPage(wordpress_id: { eq: 2 } ) {
      content
    }
  }
`

export default IndexPage
